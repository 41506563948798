import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResetPassword } from '../models/reset-password';
import { environment } from './../../../../src/environments/environment';
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root',
})
export class SigninService {
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient, private cookieService: CookieService) {
  }

  signIn(userName: string, password: string, isPartu: boolean) {
    const Fbp = this.cookieService.get('_fbp') || null;
    const Fbc = this.cookieService.get('_fbc') || null;
    console.log(Fbp, Fbc, 'loggerr')
    const mUrl = `${this.apiUrl}account/login-user`;
    return this.http.post(mUrl, {userName, password, isPartu, Fbp, Fbc});
  }

  loginStatus(): boolean {
    const token = JSON.parse(localStorage.getItem('Token') || '{}');
    if (
      Object.prototype.hasOwnProperty.call(token, 'accessToken') &&
      token.accessToken
    ) {
      return true;
    }
    return false;
  }

  recoverPassword(data: ResetPassword) {
    return this.http.post(`${this.apiUrl}account/forgot-password-reset`, data);
  }
}
